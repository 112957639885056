import React from 'react'
import '../App.css'
import zikr from '../Images/zikr.png'
import sujood from '../Images/sajd.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { useState, useEffect } from 'react';



const Home = () => {




// whatsapp visible function. it will appear only on scroll
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {


    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {  // Adjust this value based on when you want the button to appear
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);













  return (
    <>
    <div>
      
        <div className="flex flex-col items-center py-10 text-white"  >
          {/* first */}
          <div className="flex flex-row items-center max-sm:flex-col">
          <div class="flex flex-col items-center text-center" data-aos="slide-right">
              <h1 class="text-4xl md:text-6xl font-bold mb-4">Al Zikr Online Institute</h1>
              <p class="text-lg md:text-xl px-8 lg:px-32 py-4 leading-relaxed">
              Catering to a global audience, Al Zikr Online Institute has been a beacon of excellence in Islamic education for years. We take immense pride in the superior quality of our services and courses, feeling deeply privileged to serve our community. Alhamdulillah!
              </p>
          </div>
          <figure >
            <img src={zikr} />
          </figure>

          </div>

{/* second */}
          <div className="flex flex-row items-center  max-sm:flex-col">
            <figure className="pl-16 max-sm:hidden">
              <img src={sujood}/>
            </figure>



          <div className="flex flex-col items-center text-center" data-aos="slide-left">

            <h1 className="text-4xl md:text-6xl font-bold mb-4" data-aos="zoom-in" >
              About Al Zikr<br/> Online Institute
            </h1>

            <p className="text-lg md:text-xl px-8 lg:px-32 py-4 leading-relaxed">
            Al Zikr Online Institute is an exceptional platform for online Quran teaching, enabling new Muslims and 
            children worldwide to read the Quran with Tajweed from the comfort of their homes. We welcome all 
            students interested in learning the Quran. Our program includes Quran reading (Nazra Quran), Nurani 
            Qaida, Quran memorization, translation, and prayers (Duas with the 6 Kalimas). With just one click, 
            you can start learning from our highly trained, qualified, and dedicated male and female Quran 
            teachers who are available 24/7. Click here for a free trial and, Insha'Allah, you and your children 
            can begin your Quran learning journey immediately.
            </p>

          </div>
          </div>

            

        </div>
    </div>

    {/* Whatsapp button */}
        {isVisible && (
            <button
              
              className="fixed bottom-10 right-5 w-32 p-4 z-10"
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="size-16 text-white"
                data-aos="zoom-out"
              />
            </button>
          )}


    </>
  )
}

export default Home
