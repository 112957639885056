import React from 'react'

const Packages = () => {
  return (
    <>
      
      <div>

        <div className="flex flex-col items-center gap-10 py-10" >

            <div className="text-5xl write " data-aos="zoom-in"> <b className="text-black">Packages</b> </div>

            {/* cards */}
            <div className="flex flex-row items-center max-sm:flex-col max-lg:flex-col">

                {/* card 1 */}
                <div className="flex flex-col items-center gap-5 box" data-aos="zoom-in">

                    <div className=" text-xl write1" ><b>30 Minutes Per Lesson</b></div>

                    <div className=" text-4xl write1" >2 lessons a week</div>

                    <p className="write1" >Noorani Qaida</p>
                    <p className="write1" >Tajweed al-Quran</p>
                    <p className="write1" >Quran Reading & Recitation</p>
                    <p className="write1" >Salah (Prayer)</p>
                    <p className="write1" >Seerah & Islamic Studies</p>
                    <p className="write1" >Quran Memorization</p>
                    <p className="write1" >Full learning programme</p>
                    <p className="write1" >for kids or adults</p>

                    <div className="text-5xl text-blue-600 write1" ><b>$54</b> / mo</div>
                    <button className="inputs1" >Select</button>

                    <div className="text-5xl text-blue-600 write1" ><b>$594 </b>/ yr</div>
                    <button className="inputs1" >Select</button>

                    <p className="write1" >Only $50/mo Save $54 on annual plan</p>
                    <p className="write1" >** Discounts, special pricing and volume discounts are available. Please contact us to learn more.</p>

                </div>

                {/* card 2 */}
                <div className="flex flex-col items-center gap-5 box" data-aos="zoom-in">

                <div className=" text-xl write1" ><b>30 Minutes Per Lesson</b></div>

                <div className=" text-4xl write1" >3 lessons a week</div>

                <p className="write1" >Noorani Qaida</p>
                <p className="write1" >Tajweed al-Quran</p>
                <p className="write1" >Quran Reading & Recitation</p>
                <p className="write1" >Salah (Prayer)</p>
                <p className="write1" >Seerah & Islamic Studies</p>
                <p className="write1" >Quran Memorization</p>
                <p className="write1" >Full learning programme</p>
                <p className="write1" >for kids or adults</p>

                <div className="text-5xl text-blue-600 write1" ><b>$70</b> / mo</div>
                <button className="inputs1" >Select</button>

                <div className="text-5xl text-blue-600 write1" ><b>$770 </b>/ yr</div>
                <button className="inputs1" >Select</button>

                <p className="write1" >Only $64/mo Save $70 on annual plan</p>
                <p className="write1" >** Discounts, special pricing and volume discounts are available. Please contact us to learn more.</p>

                </div>

                {/* card 3 */}
                <div className="flex flex-col items-center gap-5 box" data-aos="zoom-in">

                <div className=" text-xl write1" ><b>30 Minutes Per Lesson</b></div>

                <div className=" text-4xl write1" >5 lessons a week</div>

                <p className="write1" >Noorani Qaida</p>
                <p className="write1" >Tajweed al-Quran</p>
                <p className="write1" >Quran Reading & Recitation</p>
                <p className="write1" >Salah (Prayer)</p>
                <p className="write1" >Seerah & Islamic Studies</p>
                <p className="write1" >Quran Memorization</p>
                <p className="write1" >Full learning programme</p>
                <p className="write1" >for kids or adults</p>

                <div className="text-5xl text-blue-600 write1" ><b>$108</b> / mo</div>
                <button className="inputs1" >Select</button>

                <div className="text-5xl text-blue-600 write1" ><b>$1188 </b>/ yr</div>
                <button className="inputs1" >Select</button>

                <p className="write1" >Only $99/mo Save $108 on annual plan</p>
                <p className="write1" >** Discounts, special pricing and volume discounts are available. Please contact us to learn more.</p>

                </div>



            </div>

        </div>


      </div>





    </>
  )
}

export default Packages
