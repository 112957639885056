import React from 'react'
import { Link } from 'react-scroll';
import logo1 from '../Images/Logo.jpeg';





const Front = () => {



  return (
    <>
    
      
      <div className="background1">
          <div className="parallax flex flex-col items-center text-white text-6xl  ">
            <Link to="home" smooth={true} duration={500} className="write" >
               
  
               <div class="flex flex-col items-center">
               {/* class="grid grid-cols-3 items-baseline" */}

                {/* logo */}
                <div className="pt-28">
                {/* className=" col-span-1 pt-28" */}
                  <div className="logo" > 
                    <figure>
                      <img src={logo1} className="size-60 max-sm:size-48 rounded-full"  alt='Al Zikr Online Institute'/>
                    </figure>
                  </div>
                </div>
                {/* institute name */}
                <div className="">
                {/* className="col-span-2" */}
                  <div className=" max-md:text-3xl">
                  <br/> &nbsp;Al Zikr Online Institute 
                  </div>
                </div>

               </div>



            </Link>
          </div>
          </div>


    </>
  )
}

export default Front
