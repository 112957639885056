import React from 'react'
import home from '../Images/Noorani.jpg'
import taqweed from '../Images/tagweed.jpg'
import Quran from '../Images/QuranReciting.jpg'
import Salah from '../Images/Salah.jpg'
import islamic from '../Images/IslamicStudies.jpg'
import hafiz from '../Images/Hafiz.jpg'


const Courses = () => {
  return (
    <>
    
      <div>
        

        <div className="flex flex-col items-center gap-10 py-10 ">

            {/* corses */}
            <div className="text-5xl write  max-sm:pl-5"  >
            Our most popular courses
            </div>

            <div className="px-16 write">Here at Al Zikr Online Institute we have a multitude of courses available, for both kids and adults alike. All our courses are beginner-friendly, and our teaches will move at a pace that is comfortable for the student. Below is an overview of a few of our most popular courses.</div>




            {/* first row */}

            <div className="flex flex-row px-10 py-10 gap-5 max-sm:flex-col" >



            {/* second */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-left">
            <div>
                <figure className="image-container">
                    <img src={taqweed} className=" size-96 waving-image" alt='Course' />
                </figure>
            </div>
            <div className="text-2xl write" >Tajweed al-Quran</div>
            <p className="text-white">RULES OF PRONOUNCIATION</p>
            <div className="write" >Tajweed or Tajweed is the set of rules governing the correct pronunciation 
            of Quranic Arabic. This course covers basic & advanced Tajweed.
            </div>
            </div>

            {/* first element */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-down">
            <div>
                <figure className="image-container">
                    <img src={home} className=" size-96 waving-image" alt='Course' />
                </figure>
            </div>
            <div className="text-2xl write" >Noorani Qaida<br/></div>
            <p className="text-white">BASICS & ARABIC ALPHABET</p>
            <div className="write" >
            “Al Qaida Al Nooraniya” is a book for beginners to learn Quranic Arabic. All beginners start 
            with this and continue on to other courses.
            </div>
            </div>

            {/* third */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-right">
            <div>
                <figure className="image-container">
                    <img src={Quran} className=" size-96 waving-image" alt='Course' />
                </figure>
            </div>
            <div className="text-2xl write" >Quran reading</div>
            <p className="text-white">AND RECITATION</p>
            <div className="write" >
            Our teachers guide and teach and their students from the beginning to “khatm-al-Quran” ayah by ayah 
            during their one-on-one reading and recitation lessons.
            </div>
            </div>


            </div>





            {/* Second row */}

            

            <div className="flex flex-row px-10 py-10 gap-5 max-sm:flex-col" >

            {/* first element */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-right">
            <div>
                <figure className="image-container">
                    <img src={Salah} className=" size-96 waving-image" alt='Course' />
                </figure>
            </div>
            <div className="text-2xl write" >Salah (Prayer)</div>
            <p className="text-white">SALAH / NAMAZ METHOD</p>
            <div className="write" >
            This course is aimed at teaching our students the proper method of Salah (Namaz). The course covers 
            “importance of salah”, “wudu”, “the five daily prayers” and “special prayers”.
            </div>
            </div>

            {/* second */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-up">
            <div>
                <figure className="image-container">
                    <img src={islamic} className=" size-96 waving-image" alt='Course' />
                </figure>
            </div>
            <div className="text-2xl write" >Islamic Studies</div>
            <p className="text-white">5 PILLARS OF ISLAM, SEERAH & TAFSIR</p>
            <div className="write" >Our Islamic studies course focuses on core principles and history of islam and 
            includes an overview of tafsir al-Quran and the seerah of our Prophet Muhammad (P.B.U.H)
            </div>
            </div>

            {/* third */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-left">
            <div>
                <figure className="image-container">
                    <img src={hafiz} className=" size-96 waving-image" alt='Course' />
                </figure>
            </div>
            <div className="text-2xl write" >Hafz al-Quran</div>
            <p className="text-white">MEMORIZATION</p>
            <div className="write" >
            A course that helps students memorize a part of the Quran or the entire Quran. 
            Our teachers implement proven, special hifdz techniques, and work with the students 
            at their own pace.
            </div>
            </div>


            </div>




        </div>




      </div>
    </>
  )
}

export default Courses
