import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faUser, faDollarSign, faSchool, faEarthAmericas, faComputer } from '@fortawesome/free-solid-svg-icons'


const WeAssure = () => {
  return (
    <>


    <div className="flex flex-col items-center background3 py-10 ">

        <div className="text-5xl write underline"> <b>WE Assure You</b> </div>

        {/* first row */}

        <div className="flex flex-row px-10 py-10 gap-5 max-sm:flex-col">

            {/* first element */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-up">
            <FontAwesomeIcon icon={faCalendarDays} className="size-16 text-white" />
            <div className="text-2xl write" >24/7 FLEXIBLE<br/> SCHEDULE</div>
            <div className="write" >
                Al Zikr Online Institute provides an exceptional online platform for learning the Quran in an engaging and 
                interactive digital environment. From the comfort of your home, you and your family can read and 
                memorize the Quran with ease. Our dedicated Quran tutors are available 24/7, ensuring that you have 
                access to quality instruction whenever you need it.
            </div>
            </div>

            {/* second */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-up">
            <FontAwesomeIcon icon={faUser} className="size-16 text-white"  />
            <div className="text-2xl write" >ONE-TO-ONE<br/> CLASSES</div>
            <div className="write" >The online Quran classes at Al Zikr Online Institute feature a one-on-one teaching approach, 
                where a single Quran teacher provides lessons to an individual student. This ensures personalized attention and 
                enhances the learning experience. With this system, every family member receives dedicated time for study with 
                their Quran teacher, promoting effective and focused Quran learning.
            </div>
            </div>

            {/* third */}
            <div className="flex flex-col items-center gap-5" data-aos="slide-up">
            <FontAwesomeIcon icon={faDollarSign} className="size-16 text-white"  />
            <div className="text-2xl write" >FREE TRIAL<br/> CLASSES</div>
            <div className="write" >
                At Al Zikr Online Institute, we bring years of dedicated experience in online Quran teaching, driven by our commitment to 
                serving the cause of Allah. We offer free trial Quran classes, allowing you to experience our quality instruction 
                firsthand. Register now for online Quran classes via Skype and begin your journey of learning today.
            </div>
            </div>


        </div>


        {/* Second row */}

        <div className="flex flex-row px-10 py-10 gap-5 max-sm:flex-col" >

        {/* first element */}
        <div className="flex flex-col items-center gap-5" data-aos="slide-up">
        <FontAwesomeIcon icon={faSchool} className="size-16 text-white" />
        <div className="text-2xl write" >FEMALE <br/> TUTORS</div>
        <div className="write" >
        Al Zikr Online Institute provides experienced female Quran tutors for sisters who prefer learning from 
        female instructors. Our dedicated female tutors ensure a comfortable and supportive learning environment 
        for all students.
        </div>
        </div>

        {/* second */}
        <div className="flex flex-col items-center gap-5" data-aos="slide-up">
        <FontAwesomeIcon icon={faEarthAmericas} className="size-16 text-white"  />
        <div className="text-2xl write" >MULTILINGUAL <br/> TEACHERS</div>
        <div className="write" >Al Zikr Online Institute features professional teachers who are fluent in Arabic, 
        English, and Urdu. This multilingual capability ensures effective communication and enhances the learning 
        experience during Quran classes.
        </div>
        </div>

        {/* third */}
        <div className="flex flex-col items-center gap-5" data-aos="slide-up">
        <FontAwesomeIcon icon={faComputer} className="size-16 text-white"  />
        <div className="text-2xl write" >ANYWHERE, <br/> ANY DEVICE</div>
        <div className="write" >
        For your convenience, Al Zikr Online Institute allows you to learn the Quran anytime, anywhere, and on any 
        device. Whether you use a PC, iPhone, or Android device, you can take your Quran classes at a time that 
        suits you best.

        </div>
        </div>


        </div>




    </div>



      
    </>
  )
}

export default WeAssure
