// LoadingScreen.js
import React from 'react';
import '../App.css';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            Loading...
        </div>
    );
};

export default LoadingScreen;
