import React from 'react'
import '../App.css'


const Footer = () => {



  return (
    <>
    <div className=" px-2">
      <div className="flex flex-row justify-around pt-10 max-sm:flex-col max-sm:gap-5 footer ">
        <div className=" max-sm:w-full w-1/4">
            <div className="text-green-800 text-xl font-bold">Al Zikr Online Institute</div>
            <p className="font-light text-sm pt-5 max-sm:w-screen">
            Where our mission is to provide authentic and unbiased information according to the Sunnah and Quran, 
            our aim is to ensure that individuals receive reliable guidance rooted in Islamic teachings. We strive 
            to present content that adheres strictly to the principles outlined in the Sunnah and the Quran, 
            fostering a platform where seekers of knowledge can find accurate and trustworthy resources.
            </p>
        </div>



        <div>
            <div className="font-semibold font-serif hover:text-green-700">CATEGORIES</div>
            <div className="font-light hover:text-green-700">Ahkaam</div>
            <div className="font-light hover:text-green-700">Sigin</div>
            <div className="font-light hover:text-green-700">Login</div>
            <div className="font-light hover:text-green-700">Join Us</div>

        </div>



        <div>
            <div className="font-semibold font-serif hover:text-green-700">ABOUT US</div>
            <div className="font-light hover:text-green-700">The Company</div>
            <div className="font-light hover:text-green-700">Packages</div>
            <div className="font-light hover:text-green-700">Privacy Policy</div>
            <div className="font-light hover:text-green-700">FAQS</div>
        </div>



        <div>
            <div className="font-semibold font-serif hover:text-green-700">FOLLOW US</div>
            <div className="font-light hover:text-green-700">Facebook</div>
            <div className="font-light hover:text-green-700">Instagram</div>
            <div className="font-light hover:text-green-700">Youtube</div>


            <div>
            <div className="font-semibold font-serif pt-10 hover:text-green-700">CONTACT US</div>
            <div className="font-light hover:text-green-700">+92 xxxxxxxxxx</div>
            <div className="font-light hover:text-green-700">xxxxxxxx@gmail.com</div>
        </div>

        </div>

      </div>



      {/* flex 3 copyright */}
      <div className="flex flex-row justify-center pt-10 footer">
      Copyright © 2024
      </div>
    </div>
    </>
  )
}

export default Footer
