import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Footer from './components/Footer';
import WeAssure from './components/WeAssure';
import Courses from './components/Courses';
import Packages from './components/Packages';
import Front from './components/Front';
import React, { useEffect, useState } from 'react';
import LoadingScreen from './components/LoadingScreen';



function App() {


  // loading
  const [loading, setLoading] = useState(true);
  const [resourcesLoaded, setResourcesLoaded] = useState({
      images: false,
      data: false,
      // add other resources here
  });

  useEffect(() => {
      // Simulate data loading
      fetch('/api/data')
          .then(response => response.json())
          .then(data => {
              // Process your data
              setResourcesLoaded(prevState => ({ ...prevState, data: true }));
          })
          .catch(error => {
              console.error("Error fetching data:", error);
              setResourcesLoaded(prevState => ({ ...prevState, data: true }));
          });
  }, []);

  useEffect(() => {
      const images = document.images;
      const totalImages = images.length;
      let imagesLoaded = 0;

      const imageLoaded = () => {
          imagesLoaded++;
          if (imagesLoaded === totalImages) {
              setResourcesLoaded(prevState => ({ ...prevState, images: true }));
          }
      };

      if (totalImages === 0) {
          setResourcesLoaded(prevState => ({ ...prevState, images: true }));
      } else {
          for (let i = 0; i < totalImages; i++) {
              const image = images[i];
              if (image.complete) {
                  imageLoaded();
              } else {
                  image.addEventListener('load', imageLoaded);
                  image.addEventListener('error', imageLoaded); // Consider image load error as loaded
              }
          }
      }
  }, []);

  useEffect(() => {
      const allResourcesLoaded = Object.values(resourcesLoaded).every(Boolean);
      if (allResourcesLoaded) {
          setLoading(false);
      }
  }, [resourcesLoaded]);
  // loading






  useEffect(() => {
    AOS.init({
      offset: 120,
      duration: 1200,
    });
  }, []);





  return (
    <>
        <div>
          {loading ? <LoadingScreen/> : (
          <div>
          <Navbar />
          <Front/>

          <section id="home" className="">
            <div className=" background1 ">
            <Home/>
            </div>
          </section>

          <div className="parallax1" ></div>



          <section id="courses" className="">
            <div className="pt-52 background3 pb-32">
            <Courses/>
            </div>
          </section>

          <div className="parallax1" ></div>


          <section id="Packages" className="">
            <div className=" background2 pb-28 ">
            <Packages/>
            </div>
          </section>
  
          <div className="parallax1" ></div>


          
          <WeAssure/>
          <div className="parallax2" ></div>
          
          <Footer/>
          </div>)}
        </div>
    </>
  );
}

export default App;

