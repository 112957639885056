// Navbar.js
import React from 'react';
import { Link } from 'react-scroll';
import logo from '../Images/Logo.jpeg'

const Navbar = () => {
  return (
    <div  className="flex flex-row justify-around navbar fixed top-0 left-0 w-full write ">

      <div className="logo" > 
      <figure>
        {/* <img src={logo} className="size-12 rounded-full" /> */}
      </figure>
      </div>




    <div>
    <nav>
      <ul >
        <li>
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link to="courses" smooth={true} duration={500}>
            Courses
          </Link>
        </li>
        <li>
          <Link to="Packages" smooth={true} duration={500}>
          Packages
          </Link>
        </li>
      </ul>
    </nav>
    </div>
    </div>
  );
};

export default Navbar;
